.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

form {
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

input[type="text"], select {
  padding: 10px;
  margin: 5px 0; /* Adjusted margin for consistency */
  border-radius: 20px;
  border: 1px solid #ddd;
  width: 300px; /* Ensure the width matches for both input and select */
  text-align: center;
}

button {
  padding: 10px 20px;
  margin-top: 5px;
  border-radius: 20px;
  border: none;
  background-color: #4285F4; /* Google's blue */
  color: white;
  font-weight: bold;
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin: 10px 0;
}
